import React from "react";
import { useSpring, animated } from "react-spring";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FaWifi, FaDesktop, FaRegClock } from 'react-icons/fa';
import Card from "react-bootstrap/Card";
import CardItem from "../CardItem/CardItem";
import TwoCol from "../TwoCol/TwoCol";
import Adobe from "../assets/images/Adobe.png";
import Amazon from "../assets/images/Amazon.png";
import Microsoft from "../assets/images/Microsoft.png";
import Urban from "../assets/images/Urban.png";
import Zomoto from "../assets/images/Zomoto.png";
import BankingImg from "../assets/icons/Banking.png";
import BPOImg from "../assets/icons/BPO.png";
import ConsultingImg from "../assets/icons/Consulting.png";
import HumanImg from "../assets/icons/Human.png";
import ITImg from "../assets/icons/IT.png";
import LegalImg from "../assets/icons/Legal.png";
import OperationsImg from "../assets/icons/Operations.png";
import SalesImg from "../assets/icons/Sales.png";
import Hire1 from "../assets/images/hire1.png";
import Hire2 from "../assets/images/hire2.png";
import Hire3 from "../assets/images/hire3.png";
import AuthTabs from '../AuthTabs/AuthTabs';


const LandingPage = () => {
  const animationProps = useSpring({ opacity: 1, from: { opacity: 0 } });

  // Browse by Category Cards
  const cardsData = [
    { title: "Banking & Finance", imgUrl: BankingImg, className: "bg-white p-5 min-card" },
    { title: "BPO", imgUrl: BPOImg, className: "bg-white p-5 min-card" },
    { title: "Consulting", imgUrl: ConsultingImg, className: "bg-white p-5 min-card" },
    { title: "Human Resources", imgUrl: HumanImg, className: "bg-white p-5 min-card" },
    { title: "IT & Systems", imgUrl: ITImg, className: "bg-white p-5 min-card" },
    { title: "Legal", imgUrl: LegalImg, className: "bg-white p-5 min-card" },
    { title: "Operations", imgUrl: OperationsImg, className: "bg-white p-5 min-card" },
    { title: "Sales & Marketing", imgUrl: SalesImg, className: "bg-white p-5 min-card" },
  ];

  const cardsData2 = [
    { title: "01", description: "Register your account", className: "bg-light px-5 py-3" },
    { title: "02", description: "Apply for the job", className: "bg-skyblue px-5 py-3" },
    { title: "03", description: "Upload your resume", className: "bg-lipink px-5 py-3" },
    { title: "04", description: "Scheduled Interviews", className: "bg-liyellow px-5 py-3" },
  ];


  const jobsType = [
    { imgUrl: <FaWifi />, title: 'Remote Jobs', count: '2,920', className: 'text-primary' },
    { imgUrl: <FaDesktop />, title: 'Fulltime Jobs', count: '3,810', className: 'text-secondary' },
    { imgUrl: <FaRegClock />, title: 'Parttime Jobs', count: '1,258', className: 'text-success' },
  ];


  const badgeRow = [
    { badgetext: 'Freshers' },
    { badgetext: 'HR Executive' },
    { badgetext: 'Manual Testing' },
    { badgetext: 'Software Engineer' },
    { badgetext: 'Business Analyst' },
    { badgetext: 'DevOps Engineer' },
    { badgetext: 'Data Analyst' },
    { badgetext: 'Digital Marketing' },
    { badgetext: 'Banking' },
    { badgetext: 'UX/UI' },
  ];

  const twoCol = [
    {
      imgUrl: Hire1, // Replace with the actual image URL or import
      title: "Get Access to Talent",
      description: "Post a job in minutes and quickly get your job in front of the relevant candidates",
      className: "p-4",
      versa: true,
    },
    {
      imgUrl: Hire2, // Replace with the actual image URL or import
      title: "Easily Screen Candidates Across India",
      description: "Save time on screening candidates while our Machine Learning algorithms do the heavy lifting of sorting applications relevant to your job posting",
      className: "p-4",
      versa: false,
    },
    {
      imgUrl: Hire3, // Replace with the actual image URL or import
      title: "Use Smart Tools for Indian Recruitment",
      description: "Tools like chat, video/audio calls, and interview scheduler help you reduce manual work and connect with the Indian candidates, quickly, and efficiently",
      className: "p-4",
      versa: true,
    },
  ];
  return (
<>
    <div className="bg-white js-banner">
      <div className="vertical-gradient">
        <div className="container py-0 py-lg-5">
            <div className="row py-4 align-items-center justify-content-center">
                <div className="col-md-6 col-lg-6 col-xl-7 mb-4 px-4 px-lg-2">
                  <span className="text-primary fs-4">Best Hiring Platform in India</span>
                  <h1 className="display-4 fw-bold my-3">Find the Right<br/>
                  Candidates. Fast.</h1>
                  <p className="mb-4 fs-5">
                  Trusted by 5 Cr+ Candidates | 2 Lakh+ Employers Across India
                  </p>
                  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_561_5828"  maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
<rect width="64" height="64" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_561_5828)">
<path d="M13.3352 45.1499V42.5036H17.6429V21.5361C17.6429 18.0789 18.7454 15.0381 20.9506 12.4138C23.1557 9.78942 25.9506 8.15494 29.3352 7.51032V6.065C29.3352 5.33553 29.5959 4.71211 30.1173 4.19474C30.6386 3.67733 31.2668 3.41863 32.0019 3.41863C32.7369 3.41863 33.3651 3.67733 33.8865 4.19474C34.4078 4.71211 34.6685 5.33553 34.6685 6.065V7.51032C38.0531 8.15494 40.848 9.78942 43.0531 12.4138C45.2583 15.0381 46.3609 18.0789 46.3609 21.5361V42.5036H50.6685V45.1499H13.3352ZM32.0019 52.0713C30.8087 52.0713 29.7925 51.6548 28.9531 50.8218C28.1138 49.9889 27.6942 48.9804 27.6942 47.7963H36.3095C36.3095 48.9804 35.8899 49.9889 35.0506 50.8218C34.2113 51.6548 33.195 52.0713 32.0019 52.0713ZM8.61719 21.4852C8.61719 17.6175 9.48643 14.0627 11.2249 10.8209C12.9634 7.57902 15.2941 4.89784 18.2173 2.77734L19.7609 4.92501C17.1967 6.79785 15.1429 9.16942 13.5993 12.0397C12.0557 14.91 11.2839 18.0585 11.2839 21.4852H8.61719ZM52.7198 21.4852C52.7198 18.0585 51.948 14.91 50.4045 12.0397C48.8609 9.16942 46.807 6.79785 44.2429 4.92501L45.7865 2.77734C48.7096 4.89784 51.0403 7.57902 52.7788 10.8209C54.5173 14.0627 55.3865 17.6175 55.3865 21.4852H52.7198Z" fill="#86CF4E"/>
</g>
</svg>



                  <svg width="64" className="ms-2" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_561_5831" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
<rect width="63.4404" height="63.4404" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_561_5831)">
<path d="M26.2486 33.7942L31.7183 29.597L37.0558 33.6933L34.992 27.0948L40.6651 22.7362H33.9143L31.7183 16.0368L29.5223 22.7362H22.7715L28.3124 27.0948L26.2486 33.7942ZM31.7183 50.482C26.4079 48.9182 21.9963 45.7257 18.4838 40.9047C14.9712 36.0836 13.2148 30.653 13.2148 24.6128V10.4272L31.7183 3.56641L50.2218 10.4272V24.6128C50.2218 30.653 48.4655 36.0836 44.9528 40.9047C41.4403 45.7257 37.0287 48.9182 31.7183 50.482Z" fill="#7749F8"/>
</g>
</svg>


<svg width="54" height="54" className="ms-3" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_561_5834"  maskUnits="userSpaceOnUse" x="0" y="0" width="54" height="54">
<rect width="53.6255" height="53.6255" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_561_5834)">
<path d="M17.8751 26.4798H35.7503V25.2603C35.7503 23.6342 34.931 22.3222 33.2925 21.3244C31.6539 20.3266 29.494 19.8276 26.8127 19.8276C24.1314 19.8276 21.9715 20.3266 20.333 21.3244C18.6944 22.3222 17.8751 23.6342 17.8751 25.2603V26.4798ZM26.8127 17.6102C28.0416 17.6102 29.0937 17.176 29.9688 16.3075C30.8439 15.439 31.2815 14.395 31.2815 13.1755C31.2815 11.9559 30.8439 10.9119 29.9688 10.0434C29.0937 9.17491 28.0416 8.74067 26.8127 8.74067C25.5838 8.74067 24.5318 9.17491 23.6566 10.0434C22.7815 10.9119 22.3439 11.9559 22.3439 13.1755C22.3439 14.395 22.7815 15.439 23.6566 16.3075C24.5318 17.176 25.5838 17.6102 26.8127 17.6102ZM4.46875 44.219V4.30588C4.46875 3.08632 4.90632 2.04229 5.78146 1.17381C6.6566 0.305333 7.70862 -0.128906 8.93754 -0.128906H44.6879C45.9168 -0.128906 46.9688 0.305333 47.844 1.17381C48.7191 2.04229 49.1567 3.08632 49.1567 4.30588V30.9146C49.1567 32.1342 48.7191 33.1782 47.844 34.0467C46.9688 34.9152 45.9168 35.3494 44.6879 35.3494H13.4063L4.46875 44.219Z" fill="#00997D"/>
</g>
</svg>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-5">
                <AuthTabs />
                </div>
            </div>
        </div>
      </div>

      {/* For Companies */}
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-md-12 mb-5">
            <h2 className="text-center display-6 mb-4 fw-semibold">Top Indian Tech Companies Hiring on jobsco.com</h2>
          </div>
          <div className="col-md-12 mb-4 text-center">
            <Swiper
              spaceBetween={50}
              slidesPerView={5}
              centeredSlides={false}
              loop={true}
              aria-live="off"
              style={{ transitionDuration: "0ms", transform: "translate3d(-0px, 0px, 0px)" }}
              breakpoints={{
                320: { // Mobile devices
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                640: { // Tablets and up
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: { // Desktops and up
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
            >
              <SwiperSlide aria-label="1 / 6" style={{ width: "160px", height: "60px", marginRight: "50px" }}>
                <img src={Adobe} alt="Adobe Image" />
              </SwiperSlide>
              <SwiperSlide aria-label="2 / 6" style={{ width: "160px", marginRight: "60px" }}>
                <img src={Amazon} alt="Amazon Image" />
              </SwiperSlide>
              <SwiperSlide aria-label="3 / 6" style={{ width: "160px", marginRight: "60px" }}>
                <img src={Microsoft} alt="Microsoft Image" />
              </SwiperSlide>
              <SwiperSlide aria-label="4 / 6" style={{ width: "160px", marginRight: "60px" }}>
                <img src={Urban} alt="Urban Image" />
              </SwiperSlide>
              <SwiperSlide aria-label="5 / 6" style={{ width: "160px",  height: "60px", marginRight: "50px" }}>
                <img src={Zomoto} alt="Zomoto Image" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="bg-primary mt-4">
        <div className="container">
          <div className="d-lg-flex d-sm-block py-5 justify-content-around">
            <div className="text-center py-4">
              <h3 className="display-3 fw-bold text-white">5K+</h3>
              <p className="text-white fs-5">Jobs are posted every day</p>
            </div>
            <div className="text-center py-4">
              <h3  className="display-3 fw-bold text-white">24K+</h3>
              <p className="text-white fs-5">Active recruiters</p>
            </div>
            <div className="text-center py-4">
              <h3  className="display-3 fw-bold text-white">73K+</h3>
              <p className="text-white fs-5">Job applications are submitted daily</p>
            </div>
          </div>
        </div>

      </div>


      {/* Hiring is Now Simple */}
        <div className="bg-skyblue pt-5">
          <div className="container-md pb-5">
          <div className="justify-content-center text-center row mt-4">
          <div className="col-lg-12 col-xl-10">
        <h2 className="text-center display-6 fw-semibold">Hiring is Now Simple in India</h2>
          <p className="fs-5 pt-2">Reach out to quality candidates across all major Indian domains </p>
          </div>
        </div>
            <div className="pt-3">
              <div className="row">
                {twoCol.map((card, index) => (
                  <TwoCol key={index} twoCol={card} />
                ))}
              </div>
            </div>
          </div>
        </div>



      <div className="container py-5">
        <div className="justify-content-center text-center row mt-4">
        <div className="col-12">
        <h2 className="text-center display-6 fw-semibold">Explore Talent Pool</h2>
          <p className="fs-5 pt-2">Reach out to quality candidates across all top domains in India</p>
          </div>
        </div>
        <animated.div style={animationProps}>
          <div className="row pt-4">
            {cardsData.map((card, index) => (
              <CardItem key={index} cardDetails={card} />
            ))}
          </div>
        </animated.div>
      </div>
    </div>

    <div className="bg-skyblue pt-5">
          <div className="container-md pb-5">
          <div className="justify-content-center text-center row mt-4">
            <div className="col-lg-12 col-xl-6">
        <h2 className="text-center display-6 fw-semibold">FAQ'S</h2>
          <p className="fs-5 pt-2">Got a question to ask us? Use the list below to find answers to our most frequently asked questions.</p>
        </div>
        </div>
<div class="row justify-content-center text-start">
<div class="col-sm-12 col-lg-12 col-xl-9">
<div className="accordion my-4" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button
        className="accordion-button py-4 py-4"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        What is the purpose of this platform?
      </button>
    </h2>
    <div
      id="collapseOne"
      className="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        Our platform connects employers with talented candidates. It simplifies the hiring process by offering advanced tools for job posting, candidate screening, and communication.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button
        className="accordion-button py-4 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwo"
        aria-expanded="false"
        aria-controls="collapseTwo"
      >
        How do I post a job?
      </button>
    </h2>
    <div
      id="collapseTwo"
      className="accordion-collapse collapse"
      aria-labelledby="headingTwo"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        To post a job, simply create an account, navigate to the job posting section, and fill in the required details about the job and your company. Your job will be visible to potential candidates immediately.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button
        className="accordion-button py-4 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseThree"
        aria-expanded="false"
        aria-controls="collapseThree"
      >
        Can I filter candidates?
      </button>
    </h2>
    <div
      id="collapseThree"
      className="accordion-collapse collapse"
      aria-labelledby="headingThree"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        Yes, our platform provides various filters to help you find the best candidates based on skills, experience, location, and more.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button
        className="accordion-button py-4 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseFour"
        aria-expanded="false"
        aria-controls="collapseFour"
      >
        How do I purchase jobs or resume Database access online?
      </button>
    </h2>
    <div
      id="collapseFour"
      className="accordion-collapse collapse"
      aria-labelledby="headingFour"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        To purchase jobs or resume Database access, log in to your account, navigate to the purchase section, and select the package that best fits your needs. Follow the prompts to complete the payment process.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFive">
      <button
        className="accordion-button py-4 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseFive"
        aria-expanded="false"
        aria-controls="collapseFive"
      >
        How do I log in as a recruiter?
      </button>
    </h2>
    <div
      id="collapseFive"
      className="accordion-collapse collapse"
      aria-labelledby="headingFive"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        To log in as a recruiter, go to the login page, enter your recruiter credentials (email and password), and click the "Log In" button. If you don’t have an account, you can register by clicking on the "Sign Up" button.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingSix">
      <button
        className="accordion-button py-4 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseSix"
        aria-expanded="false"
        aria-controls="collapseSix"
      >
        I have forgotten my password or am having trouble logging in.
      </button>
    </h2>
    <div
      id="collapseSix"
      className="accordion-collapse collapse"
      aria-labelledby="headingSix"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        If you have forgotten your password or are having trouble logging in, click the "Forgot Password" link on the login page. Follow the instructions to reset your password. If you continue to have issues, contact support for assistance.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingSeven">
      <button
        className="accordion-button py-4 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseSeven"
        aria-expanded="false"
        aria-controls="collapseSeven"
      >
        When will my job ad go live?
      </button>
    </h2>
    <div
      id="collapseSeven"
      className="accordion-collapse collapse"
      aria-labelledby="headingSeven"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        Once you submit your job ad, it typically goes live within a few hours. You will receive a notification once it is published.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingEight">
      <button
        className="accordion-button py-4 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseEight"
        aria-expanded="false"
        aria-controls="collapseEight"
      >
        How long will my job advertisement(s) appear on the site?
      </button>
    </h2>
    <div
      id="collapseEight"
      className="accordion-collapse collapse"
      aria-labelledby="headingEight"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        Job advertisements remain live on the site for the duration specified in your subscription plan or until the position is filled, whichever comes first.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingNine">
      <button
        className="accordion-button py-4 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseNine"
        aria-expanded="false"
        aria-controls="collapseNine"
      >
        How do I get a VAT receipt for online payments?
      </button>
    </h2>
    <div
      id="collapseNine"
      className="accordion-collapse collapse"
      aria-labelledby="headingNine"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        VAT receipts for online payments are sent to the email address associated with your account. You can also access and download receipts from the billing section in your account settings.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTen">
      <button
        className="accordion-button py-4 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTen"
        aria-expanded="false"
        aria-controls="collapseTen"
      >
        How can I keep my details safe online?
      </button>
    </h2>
    <div
      id="collapseTen"
      className="accordion-collapse collapse"
      aria-labelledby="headingTen"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        To keep your details safe online, use a strong and unique password, enable two-factor authentication if available, and be cautious of phishing attempts. Regularly update your passwords and monitor your account for any suspicious activity.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingEleven">
      <button
        className="accordion-button py-4 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseEleven"
        aria-expanded="false"
        aria-controls="collapseEleven"
      >
        How do I make a complaint?
      </button>
    </h2>
    <div
      id="collapseEleven"
      className="accordion-collapse collapse"
      aria-labelledby="headingEleven"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        To make a complaint, please contact our support team via email or through the contact form on our website. Provide details about your complaint, and we will address it as soon as possible.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwelve">
      <button
        className="accordion-button py-4 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwelve"
        aria-expanded="false"
        aria-controls="collapseTwelve"
      >
        How do I pay my invoice online?
      </button>
    </h2>
    <div
      id="collapseTwelve"
      className="accordion-collapse collapse"
      aria-labelledby="headingTwelve"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        To pay your invoice online, log in to your account, navigate to the billing section, and select the invoice you wish to pay. Follow the instructions to complete the payment using your preferred payment method.
      </div>
    </div>
  </div>
  </div>
  </div>
</div>
</div>
</div>
</>

  );
};
export default LandingPage;
