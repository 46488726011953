import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'; // Added useNavigate for redirection after delete
import { LuWallet } from "react-icons/lu";
import AutocompleteDropdown from '../AutocompleteDropdown/AutocompleteDropdown';
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { jobPosts } from '../ActivePost/ActivePost';
import { MdModeEdit } from "react-icons/md";
import { GoLinkExternal } from "react-icons/go";
import { MdDeleteOutline } from "react-icons/md";
import DeleteModal from '../DeleteModal/DeleteModal';
import { Tooltip } from 'bootstrap';

const JobDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const job = jobPosts.find((p) => p.id === parseInt(id, 10));
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  useEffect(() => {
    // Initialize Bootstrap tooltips
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
        new Tooltip(tooltipTriggerEl);
    });
  }, []); // Empty dependency array to run only once

  const handleDeleteClick = () => {
    setShowModal(true); // Show the delete confirmation modal
  };

  const handleDelete = () => {
    // Logic to delete the job post
    console.log(`Deleted job with id: ${job.id}`);
    setShowModal(false); // Hide the modal after delete
    navigate('/jobposts'); // Navigate back to job posts list after deletion
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal without deleting
  };

  if (!job) {
    return <div>Profile not found.</div>;
  }

  return (
    <div className="js-pg-hg">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/jobposts" className='text-primary text-decoration-none'>Job Posts</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {job.jobtitle}
            </li>
          </ol>
        </nav>
        <div className="d-lg-flex d-sm-block flex-row align-items-center justify-content-between">
          <div className='js-mn-title flex-grow-1'>
            <h2 className='fw-bold pb-2'>Job Post <span class="badge ms-2 bg-light text-dark">Active Post</span></h2>
            <p>This post is active stage jobseekers can see this post </p>
          </div>
          <div className="mt-0 js-select">
                        <div className="form-group mb-4">
                            <label for="New Password" className="form-label">Select Recruiters</label>
                            <AutocompleteDropdown />
                        </div>
                    </div>
                    <div className="mt-0 js-select-add">
                        <Link to="/editjobpost"
                                    title="Edit Job Post" className='btn mt-2 ms-4 px-4 py-3 bg-white btn-link border'><MdModeEdit /></Link>
                    </div>
          <div className="mt-0 js-select-add">
            <button data-bs-toggle="tooltip" data-bs-placement="top" title="Delete this post" className='btn mt-2 ms-3 px-4 py-3 bg-white btn-link border' onClick={handleDeleteClick}><MdDeleteOutline /></button>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 col-xl-8'>
            <div className="card rounded-4 mb-4">
              <div className="d-lg-flex js-pst d-sm-block align-items-stretch p-4">
                <div className="flex-grow-1">
                  <h5 className="card-title">{job.jobtitle}</h5>
                  <span className="card-text">{job.company}</span>
                  <div className="d-lg-flex d-sm-block">
                    <div className="card-text mt-3 me-3">
                      <LuWallet className="me-2" /> {job.salary}
                    </div>
                    <div className="card-text mt-3 me-3">
                      <PiSuitcaseSimpleLight className="me-2" /> {job.experience}
                    </div>
                    <div className="card-text mt-3">
                      <CiLocationOn className="me-2" /> {job.location}
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <div className="card-text">
                      <HiOutlineAcademicCap className="me-2" /> {job.education}
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <div className="card-text">
                      <IoSettingsOutline className="me-2" /> {job.skills}
                    </div>
                  </div>
                </div>
                <div>
                  <img src={job.image} className="img-thumbnail rounded-circle border-0" alt={job.name} style={{ maxWidth: '100px' }} />
                </div>
              </div>
              <div className="d-lg-flex d-sm-block justify-content-start border-top mx-4">
                <div className="py-3 pe-5 border-lg-end">
                  <label className="small">Job Type</label>
                  <p className="card-text pt-2">Full Time</p>
                </div>
                <div className="py-3 pe-5 ps-sm-3 border-end-sm">
                  <label className="small">Work Mode</label>
                  <p className="card-text pt-2">Work from office</p>
                </div>
                <div className="py-3 pe-5 ps-sm-3 border-lg-end">
                  <label className="small">No. Openings</label>
                  <p className="card-text pt-2">15</p>
                </div>

                <div className="py-3 pe-5 ps-sm-3 align-content-end vertical-bottom">
                  <label className="small d-block">Applied Applicants </label>
                  <button className='btn p-0 mt-2 m-0 mt-2 btn-link text-decoration-none'> 96 <GoLinkExternal /></button>
                </div>


              </div>
            </div>

            <div className="card rounded-4 js-info-pg mb-5 p-4 pb-0">

              <h6 className='fs-5 mb-4'>Job Description</h6>

              <div className="mb-4" id="profile">

                <h6 className='fs-6 mb-1'>Summary</h6>
                <p className='card-text pt-2'>
                  As an UI UX Architect at Gainwell, you can contribute your skills as we harness the power of technology to help our clients improve the health and well-being of the members they serve — a community’s most vulnerable. Connect your passion with purpose, teaming with people who thrive on finding innovative solutions to some of healthcare’s biggest challenges. Here are the details on this position.
                </p>


                <div className='js-skills mt-4'>
                  <h6 className='fs-6 mb-1'>Job Responsibilities</h6>
                  <div class="d-flex flex-wrap mb-3">
                    <ul>
                      <li>Design and build advanced applications for the iOS platform using Swift.</li>
                      <li>Collaborate with cross-functional teams to define, design, and ship new features.</li>
                      <li>Ensure the performance, quality, and responsiveness of applications.</li>
                      <li>Identify and correct bottlenecks and fix bugs.</li>
                      <li>Help maintain code quality, organization, and automatization.</li>
                      <li>  Continuously discover, evaluate, and implement new technologies to maximize development efficiency.</li>
                    </ul>
                  </div>
                </div>



                <div className='js-skills mt-4'>
                  <h6 className='fs-6 mb-1'>Preferred Qualifications</h6>
                  <div class="d-flex flex-wrap mb-3">
                    <ul>
                      <li>Experience with Flutter or React Native: Knowledge of cross-platform mobile development.</li>
                      <li>Published Apps: Experience with publishing applications on the Apple App Store.</li>
                      <li>Continuous Integration/Continuous Deployment: Familiarity with CI/CD pipelines.</li>
                      <li>Cloud Services: Experience with Firebase, AWS, or other cloud services.</li>
                      <li>Apple Developer Tools: Familiarity with Xcode, Instruments, and other Apple developer tools.</li>
                    </ul>
                  </div>
                </div>


                <div className='js-skills mt-4'>
                  <h6 className='fs-6 mb-1'>Education</h6>
                  <div class="d-flex flex-wrap mb-3">
                  <p className='card-text pt-2'>UG: B.Sc in Any Specialization, BCA in Any Specialization, B.Tech/B.E. in Any Specialization</p>
                  </div>
                </div>


                <div className='js-skills mt-4'>
                  <h6 className='fs-6 mb-3'>Key Skills</h6>
                  <div class="d-flex flex-wrap mb-3">
                    <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">SQL Server</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Spring Integration</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Docker</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Restfui Web Services</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Servlets</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Spring Integration</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Spring Data JPA</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Spring Cloud</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">PSQL Server</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">JQuery</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">SCSS</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">HTML5</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className='col-md-12 col-xl-4'>
            <div className='js-mn-title flex-grow-1'>
              <div className='js-skills card p-5 mb-4 rounded-4'>
                <h6 className='fs-4 mb-4'>Other info</h6>
                <div class="d-block">
                  <p className='card-text p-0 m-0 mb-4 fw-semibold'><label className='fw-600 pb-1 fw-normal d-block'>Role:</label> Technical Architect</p>
                  <p className='card-text p-0 m-0 mb-4 fw-semibold'><label className='fw-600 pb-1 fw-normal d-block'>Industry Type:</label> Software Product</p>
                  <p className='card-text p-0 m-0 mb-4 fw-semibold'><label className='fw-600 pb-1 fw-normal d-block'>Department:</label> Engineering - Software & QA</p>
                  <p className='card-text p-0 m-0 mb-4 fw-semibold'><label className='fw-600 pb-1 fw-normal d-block'>Employment Type:</label> Full Time, Permanent</p>
                  <p className='card-text p-0 m-0 mb-0 fw-semibold'><label className='fw-600 pb-1 fw-normal d-block'>Role Category:</label> Software Development</p>
                </div>
              </div>

              <div className='js-skills card p-5 mb-4 rounded-4'>
                <h6 className='fs-4 mb-4'>About Company</h6>
                <div class="d-block">
                  <p className='card-text p-0 m-0 mb-4'>
                    Tech Mahindra is a Software company that helps to create software and manages its solutions. It offers a complete package using modern technologies like AI, ML, Cloud computing, Automation, etc. It helps large enterprises with a significant contribution.
                  </p>

                  <p className='card-text p-0 m-0 mb-4 fw-semibold'><label className='fw-600 pb-1 fw-normal d-block'>Website:</label> https://www.ideazmeet.com/</p>
                  <p className='card-text p-0 m-0 mb-4 fw-semibold'><label className='fw-600 pb-1 fw-normal d-block'>Location:</label> Hitech City, Hyderabad.</p>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <DeleteModal showModal={showModal} handleClose={handleCloseModal} handleDelete={handleDelete} />
    </div>
  );
};

export default JobDetail;
