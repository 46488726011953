import React from 'react';
import { Link } from 'react-router-dom';

const Pricing = () => {
  return (
<section class="bsb-pricing-2 bg-light js-pg-hg py-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-10 col-xl-7 col-xxl-">
        <h3 class="fs-6 text-secondary mb-2 text-uppercase text-center">Our Pricing</h3>
        <h2 class="display-6 fw-bold text-center">Choose a plan that works for you</h2>
        <p  className='mb-4 mb-md-5 text-center'>Morbi non ultrices nunc, nec fringilla nisi. Vivamus aliquet urna at risus placerat bibendum.</p>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row gy-5 gy-lg-0 gx-xl-5 justify-content-md-center">
    <div class="col-12  p-lg-0 col-lg-3">
        <div class="card border-0 rounded-4 border-bottom z-index-1 shadow-lg">
        <div class="card-header rounded-top-4 color-1 p-5">
        <h2 class="h5 mb-2">Startup</h2>
            <h4 class="display-6 fw-bold text-primary mb-0">₹ 1.5 lac</h4>
            <p class="text-primary mb-0">Rupees / Per Annum</p>
            </div>
          <div class="card-body p-4 p-xxl-5">
            <ul class="list-group list-group-flush mb-4">
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>10</strong> User Login Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>2k</strong> Job Posting Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span><strong>70K</strong>  Resumes Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Employer Profile</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Email Notifications</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Schedule Reminders </span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Comprehensive Reports</span>
              </li>
            </ul>
            <Link to="/pricingform" class="btn bsb-btn-xl py-3 px-4 btn-primary">Choose Plan</Link>
          </div>
        </div>
      </div>
      <div class="col-12 p-lg-0 col-lg-3">
        <div class="card border-0 rounded-4 border-bottom shadow-sm bsb-pricing-popular">
        <div class="card-header rounded-top-4 color-2 p-5">
        <h2 class="h5 mb-2">Business</h2>
        <h4 class="display-6 fw-bold text-primary mb-0">₹ 2.5 lac</h4>
        <p class="text-primary mb-0">Rupees / Per Annum</p>
            </div>
          <div class="card-body p-4 p-xxl-5">
            <ul class="list-group list-group-flush mb-4">
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>20</strong> User Login Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>15k</strong> Job Posting Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span><strong>2.5 L</strong>  Resumes Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Employer Profile</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Email Notifications</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Schedule Reminders </span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Comprehensive Reports</span>
              </li>
            </ul>
            <a href="#!" class="btn bsb-btn-xl py-3 px-4 btn-primary">Choose Plan</a>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 col-lg-3">
        <div class="card border-0 rounded-4 border-bottom shadow-sm">
        <div class="card-header rounded-top-4 color-3 p-5">
        <h2 class="h5 mb-2">Enterprise</h2>
        <h4 class="display-6 fw-bold text-primary mb-0">₹ 5 lac</h4>
        <p class="text-primary mb-0">Rupees / Per Annum</p>
            </div>
          <div class="card-body p-3 p-xxl-5">
            <ul class="list-group list-group-flush mb-4">
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>50</strong> User Login Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>25k</strong> Job Posting Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span><strong>5L</strong>  Resumes Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Employer Profile</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Email Notifications</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Schedule Reminders </span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Comprehensive Reports</span>
              </li>
            </ul>
            <a href="#!" class="btn bsb-btn-xl py-3 px-4 btn-primary">Choose Plan</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  );
};

export default Pricing;
