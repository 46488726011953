import React from 'react';
import { Link } from 'react-router-dom';

const PricingForm = () => {
  return (
<section class="bsb-pricing-2 bg-light js-pg-hg py-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-10 col-xl-7 col-xxl-">
        <h3 class="fs-6 text-secondary mb-2 text-uppercase text-center">Our Pricing</h3>
        <h2 class="display-6 fw-bold text-center">Your Subscription Plan</h2>
        <p  className='mb-4 mb-md-5 text-center'>Morbi non ultrices nunc, nec fringilla nisi. Vivamus aliquet urna at risus placerat bibendum.</p>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row gy-5 gy-lg-0 gx-xl-5">
      <div class="col-12 col-lg-4">
        <div class="card border-0 rounded-4 border-bottom shadow-sm">
        <div class="card-header rounded-top-4 color-1 p-5">
        <h2 class="h5 mb-2">Startup</h2>
        <h4 class="display-6 fw-bold text-primary mb-0">₹ 1.5 lac</h4>
        <p class="text-primary mb-0">Rupees / Per Annum</p>
            </div>
          <div class="card-body p-4 p-xxl-5">
            <ul class="list-group list-group-flush">
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>10</strong> User Login Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>2k</strong> Job Posting Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span><strong>70K</strong>  CV Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Employer Profile</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Email Notifications</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Schedule Reminders </span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Comprehensive Reports</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-7">
        <h5>Pay Using Credit or Debit Card</h5>
        <hr class="w-100 mx-auto mb-4 mb-xl-9 border-dark-subtle" />
      <div class="mb-4">
  <label for="" class="form-label">Name on Card</label>
  <input type="text" class="form-control" id="" placeholder="Enter your name" />
</div>
<div class="mb-4">
  <label for="" class="form-label">Card</label>
  <input type="number" class="form-control" id="" placeholder="Enter your card number" />
</div>
<div className='row'>
    <div className='col-sm-6'>
    <div class="mb-4">
  <label for="" class="form-label">Expiration Date</label>
  <input type="date" class="form-control" id="" placeholder="Enter your expiration date" />
</div>
</div>
<div className='col-sm-6'>
    <div class="mb-4">
  <label for="" class="form-label">CVV</label>
  <input type="number" class="form-control" id="" placeholder="Enter your CVV" />
</div>
</div>

<div className='col-sm-6'>
    <div class="mb-4">
  <label for="" class="form-label">Country</label>
  <input type="date" class="form-control" id="" placeholder="Enter your country" />
</div>
</div>
<div className='col-sm-6'>
    <div class="mb-4">
  <label for="" class="form-label">Postal Code</label>
  <input type="number" class="form-control" id="" placeholder="Enter your postal" />
</div>
</div>
<div className='col-sm-12'>
    <div class="mb-4">
    <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
  <label class="form-check-label" for="flexCheckChecked">
  Save this card
  </label>
</div>
</div>
</div>
</div>
    <div class="mt-2">
<Link to="/addrecruiter" class="btn bsb-btn-xl py-3 px-5 btn-primary">Pay Now</Link>
</div>
        </div>
    </div>
  </div>
</section>
  );
};

export default PricingForm;
