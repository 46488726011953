import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import Scrollbar from 'react-scrollbar';



const Notification = ({ isOpen, onClose }) => {
  const handleItemClick = () => {
    onClose(); // Close the menu when an item is clicked
  };

  return (
    <Menu right isOpen={isOpen} onClose={onClose}>
      <div className='d-flex justify-content- border-bottom py-4 px-3 align-items-center vertical-bottom'>
        <div className='p-3 pb-3 pt-4'>
          <h5>You have 02 unread Notification</h5>
        </div>
        <div>
        </div>

      </div>
      <Scrollbar
        speed={0.8}
        className="custom-scrollbar"
        horizontal={false}
      >
        <p className='p-0 mx-4 px-1'>
          <div className='border-bottom d-block p-4 px-0'>
          <div className='d-flex justify-content-between px-0 py-4 pt-2'>
            <div>
              <h5 className="card-title fs-5 mb-1">Account Renewal</h5>
            </div>
            <div className="card-text small">Jan 8</div>
          </div>
          <div className='d-flex'>
            <div className="card-text small text-start">
              <p>Hello Aravind, your account expire soon. please renewal your account please contact admin. for renewal please follow below link</p>
              <button className='btn p-0 m-0 btn-link'>https://bitly.hsjdsh</button>
            </div>
          </div>
          </div>

          <div className='border-bottom d-block p-4 px-0'>
          <div className='d-flex justify-content-between px-0 py-4 pt-2'>
            <div>
              <h5 className="card-title fs-5 mb-1">Email Promotionals</h5>
            </div>
            <div className="card-text small">Jan 5</div>
          </div>
          <div className='d-flex'>
          <div className="card-text small text-start">
              <p>Be it subscribing to exclusive content or getting users into your email subscription list, leverage push notifications to lure them into subscribing.</p>
              <button className='btn p-0 m-0 btn-link'>https://bitly.hsjdsh</button>
            </div>
          </div>
          </div>

          <div className='border-bottom d-block p-4 px-0'>
          <div className='d-flex justify-content-between px-0 py-4 pt-2'>
            <div>
              <h5 className="card-title fs-5 mb-1">Keep users in the loop</h5>
            </div>
            <div className="card-text small">Dec 25</div>
          </div>
          <div className='d-flex'>
          <div className="card-text small text-start">
              <p>Be it subscribing to exclusive content or getting users into your email subscription list, leverage push notifications to lure them into subscribing.</p>
              <button className='btn p-0 m-0 btn-link'>https://bitly.hsjdsh</button>
            </div>
          </div>
          </div>

        </p>

      </Scrollbar>
    </Menu>
  );
};

export default Notification;
