import React, { useState } from 'react';
import { LuWallet } from "react-icons/lu";
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { MdBookmarkBorder } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { IoMdShare } from "react-icons/io";
import { IoMdAlarm } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

export const jobProfiles = [
  {
    id: 1,
    name: 'Samarth',
    image: 'https://i.pravatar.cc/150?img=3',
    experience: '5yrs 6m',
    education: 'B.Sc. in Computer Science',
    skills: 'JavaScript, React, Node.js',
    location: 'Gurugram, India',
    salary: '₹ 20 lacs',
    date: 'Today'
  },
  {
    id: 2,
    name: 'Trishaan',
    image: 'https://i.pravatar.cc/150?img=4',
    experience: '3yrs 2m',
    education: 'B.A. in Design',
    skills: 'Devops, Java, AWS, HTML5, Scss',
    location: 'Nodia, India',
    salary: '₹ 15 lacs',
    date: 'Yesterday'
  },

  {
    id: 3,
    name: 'Aryaveer',
    image: 'https://i.pravatar.cc/150?img=8',
    experience: '8yrs 2m',
    education: 'BS in CS, IT, relevant engineering field',
    skills: 'Figma, Adobe XD, Wireframing',
    location: 'Kolkata, India',
    salary: '₹ 10 lacs',
    date: 'Yesterday'
  },

  {
    id: 4,
    name: 'Tanish',
    image: 'https://i.pravatar.cc/150?img=5',
    experience: '7yrs 2m',
    education: 'B.S.C in Design',
    skills: 'Devops, Java, AWS, HTML5, Scss',
    location: 'Chennai, India',
    salary: '₹ 13 lacs',
    date: 'Yesterday'

  },


  // Add more profiles as needed
];

function JobProfileList() {

  const navigate = useNavigate();

  const handleProfileClick = (id) => {
    navigate(`/profile/${id}`);
  };


  return (
    <div className="container mb-4">
      <div className="row">
       {jobProfiles.map(profile => (
          <div className='col-12 card rounded-4 p-2 mb-4'  key={profile.id}
          onClick={() => handleProfileClick(profile.id)}
          style={{ cursor: 'pointer' }}>
            <div className="d-lg-flex js-pst align-items-stretch p-4" key={profile.id}>
              <div className='pe-2'>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                </div>
              </div>
              <div className='flex-grow-1'>
                <div className="me-3">
                  <h5 className="card-title">{profile.name}</h5>
                  <span className="card-text">000{profile.id}</span>
                  <div className='d-lg-flex d-sm-block'>
                    <div className="card-text mt-3 me-3">
                      <LuWallet className="me-2" /> {profile.salary}
                    </div>
                    <div className="card-text mt-3 me-3">
                      <PiSuitcaseSimpleLight className="me-2" /> {profile.experience}
                    </div>
                    <div className="card-text mt-3">
                      <CiLocationOn className="me-2" /> {profile.location}
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <div className="card-text">
                      <HiOutlineAcademicCap className="me-2" /> {profile.education}
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <div className="card-text">
                      <IoSettingsOutline className="me-2" /> {profile.skills}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img src={profile.image} className="img-thumbnail rounded-circle border-0" alt={profile.name} style={{ maxWidth: '80px' }} />
              </div>
            </div>
            <div className='d-lg-flex d-sm-block justify-content-between border-top border-bottom mx-4 mt-2'>
              <div className='py-3'>
                <label className='small'>Pref Locations</label>
                <p className="card-text pt-2">Hyderabad, Chennai, Pune, Remote</p>
              </div>
              <div className='py-3'>
                <label className='small'>Notice Period</label>
                <p className="card-text pt-2">Less than 15 day’s</p>
              </div>
              <div className='py-3'>
                <p className="card-text pt-2">CV updated 3 days ago</p>
              </div>
            </div>
            <div className='d-lg-flex d-sm-block align-content-center justify-content-between mx-4 mt-2'>
              <div className='py-3'>
              <button className='btn fs-4 p-0 m-0 icon-clr'><MdBookmarkBorder /></button>
              <button className='btn ms-3 small px-3 py-2 btn-outline-primary'>Add Comment</button>
              </div>
              <div className='py-3'>
              <p className="card-text"><label className='small'>Seen</label> : 5 day’s ago   <button className='btn fs-4 ms-3 p-0 m-0 icon-clr'><CiMail /></button> <button className='btn fs-4 ms-2 p-0 m-0 icon-clr'><IoMdShare /></button> <button className='btn fs-3  ms-2 p-0 m-0 icon-clr'><IoMdAlarm /></button></p>

              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default JobProfileList;